<template>
    <div>
        <el-descriptions direction="vertical" :column="4" border>
            <el-descriptions-item label="手机号">{{userData.phone}}</el-descriptions-item>
            <el-descriptions-item label="昵称">{{userData.nickname}}</el-descriptions-item>
            <el-descriptions-item label="小程序openID">{{userData.wx_open_id}}</el-descriptions-item>
            <el-descriptions-item label="微信支付分">{{userData.wx_point}}</el-descriptions-item>
            <el-descriptions-item label="支付宝openID">{{userData.zfb_open_id}}</el-descriptions-item>
            <el-descriptions-item label="支付宝支付分">{{userData.zfb_point}}</el-descriptions-item>
            <el-descriptions-item label="注册时间">{{userData.created_at}}</el-descriptions-item>
        </el-descriptions>
        <!-- <div style="margin-top:30px;margin-bottom:20px;font-size:16px;">最近借用记录</div>
        <el-table :data="userOrder">
            <el-table-column property="created_at" label="下单时间" width="150"></el-table-column>
            <el-table-column property="store_name" label="商户" width="200"></el-table-column>
            <el-table-column property="address" label="地址"></el-table-column>
            <el-table-column property="use_time" label="使用时间"></el-table-column>
            <el-table-column property="amount" label="付款价格"></el-table-column>
        </el-table> -->
    </div>

  </template>
  
  <script>
    let _this;
    import {getDataById} from "@/api/users.js";
    export default {
        props:['id'],
        data(){
            return{
                userOrder:[],
                userData:[],
            }
        },
        mounted(){
            this.init()
        },
        methods:{
            // 初始化数据
            init() {
                getDataById(this.id).then(res=>{
                    if (res.code === 200){
                        this.userData = res.data
                        console.log(this.form)
                    }
                })
            },
        }
    };
  </script>
  <style lang="scss" scoped>
  .title{
    
  }
  </style>
  