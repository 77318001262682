<template>
  <section style="height: 100%">
    <div class="container">
      <el-aside style="padding-right: 20px;border-right:1px solid #e9eaf2;width: 150px;">
        <h1>审核状态</h1>
        <el-tree
          style="margin-top: 10px;margin-bottom:10px;"
          :data="status"
          node-key="id"
          icon-class="el-icon-s-flag"
          default-expand-all
          :expand-on-click-node="false"
          :highlight-current="true"
          :current-node-key="treeCurrentNode"
        >
          <span
            class="custom-tree-node"
            slot-scope="{ node, data }"
            @click="showTreeWorker(data.id, data.pid)"
          >
            <span>
              {{ data.label }}
            </span>
          </span>
        </el-tree>
      </el-aside>
      <el-main style="width: 100%;box-sizing: border-box;padding-left:20px;">
        <div
          class="worker-tooltips"
          style="display:flex;flex-wrap:wrap; width: 100%;justify-content: flex-start"
        >
          <div class="search-box"><h1>投放点列表</h1></div>
          <div class="control-box" style="margin-top: 10px;">
            <div>
              <el-input class="control-box-input" style="width:217px;margin-left:10px;margin-top:10px;" v-model="params.name" placeholder="请输入投放点名称" clearable></el-input>
              <el-select v-model="params.store_size" placeholder="投放点大小" clearable style="margin-left:10px;width:150px;margin-top:10px;">
                <el-option
                  v-for="item in sizeOptions"
                  :key="item.id"
                  :label="item.label"
                  :value="item.id">
                </el-option>
              </el-select>
              <el-select v-model="params.store_type" placeholder="投放点类型" clearable style="margin-left:10px;width:150px;margin-top:10px;">
                <el-option
                  v-for="item in storeTypeOptions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
              <el-select v-model="params.lay_type" placeholder="铺设类型" clearable style="margin-left:10px;width:150px;margin-top:10px;">
                <el-option
                  v-for="item in layTypeOptions"
                  :key="item.id"
                  :label="item.label"
                  :value="item.id">
                </el-option>
              </el-select>
              <el-select v-model="params.group_id" placeholder="请选择需搜索企业" filterable clearable style="margin-left:10px;margin-top:10px;" v-if="groupID===1">
                <el-option
                  v-for="item in groupList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
              <el-select v-model="params.province_id" placeholder="请选择省份" style="margin-left:10px;width:150px;margin-top:10px;" clearable @change="provinceClick()">
                <el-option
                  v-for="item in provinceList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
              <el-select v-model="params.city_id" placeholder="请选择市" clearable style="margin-left:10px;width:150px;margin-top:10px;" @change="cityClick()">
                <el-option
                  v-for="item in cityList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
              <el-select v-model="params.area_id" placeholder="请选择地区" clearable style="margin-left:10px;width:150px;margin-top:10px;" @change="areaClick()">
                <el-option
                  v-for="item in areaList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
              <el-input class="control-box-input" v-model="params.market" style="margin-left:10px;margin-top:10px;" placeholder="请输入市场负责人" clearable></el-input>
              <el-button icon="el-icon-search" @click="searchData" style="margin-left:10px;margin-top:10px;">搜索</el-button>
              <el-button icon="el-icon-plus" type="primary" @click="handleOpenAdd" style="margin-top:10px;">添加投放点</el-button>
            </div>
          </div>
        </div>
        <el-table
          :data="table"
          style="width: 100%;"
          tooltip-effect="dark"
          empty-text="暂无数据"
          class="table"
        >
          <el-table-column label="创建时间" prop="created_at" align="center" width="170" fixed="left"></el-table-column>
          <el-table-column label="投放点名称" prop="name" align="left" width="180" fixed="left">
            <template slot-scope="scope">
              <el-popover trigger="hover" placement="top">
                <p>投放点: {{ scope.row.name }}</p>
                <el-carousel style="width:300px;height:300px;" v-if="scope.row.store_images !== '[]'" indicator-position="none">
                  <el-carousel-item v-for="item in (scope.row.store_images === '[]' ? [] :JSON.parse(scope.row.store_images) )" :key="item">
                    <el-image style="width:100%;height:100%;" :src="item" fit="contain"></el-image>
                  </el-carousel-item>
                </el-carousel>
                <div slot="reference" class="name-wrapper" style="width-space:nowrap;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;">
                  <span>{{scope.row.name}}</span>
                </div>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column label="营业时间" prop="start_time" align="center" width="200">
            <template slot-scope="scope">
              {{scope.row.business_start_time}} 至 {{scope.row.business_end_time}}
            </template>
          </el-table-column>
          <el-table-column label="分润(%)" prop="devided" align="center">
            <template slot-scope="scope">
              <span>{{ parseFloat(scope.row.devided)*100 }}</span>
            </template>
          </el-table-column>
          <el-table-column label="投放点类型" prop="store_type" align="center" width="140">
            <template slot-scope="scope">
              {{scope.row.store_type_name}}
            </template>
          </el-table-column>
          <el-table-column label="投放点大小" prop="store_size" align="center">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.store_size === 0">普通</el-tag>
              <el-tag v-if="scope.row.store_size === 1">中型</el-tag>
              <el-tag v-if="scope.row.store_size === 2">大型</el-tag>
              <el-tag v-if="scope.row.store_size === 3">超大型</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="铺设类型" prop="lay_type" align="center">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.lay_type === 0">独家</el-tag>
              <el-tag v-if="scope.row.lay_type === 1" type="danger">共存</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="主设备数" prop="main" align="center">
            <template slot-scope="scope">
              <el-popover trigger="hover" placement="top">
                <div>
                  <el-table :data="scope.row.main_devices">
                    <el-table-column property="battery_total" label="充电宝总数" width="100" align="center"></el-table-column>
                    <el-table-column property="battery_count" label="充电宝剩余" width="100" align="center"></el-table-column>
                    <el-table-column property="serial_number" label="序列号" width="200" align="center"></el-table-column>
                  </el-table>
                </div>
                <div slot="reference" class="name-wrapper" style="width-space:nowrap;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;">
                  <span>{{scope.row.main_devices.length ? scope.row.main_devices.length : 0}}台</span>
                </div>
              </el-popover>
              
            </template>
          </el-table-column>
          <el-table-column label="总设备数" prop="device" align="center">
            <template slot-scope="scope">
              <el-popover trigger="hover" placement="top">
                <div>
                  <el-table :data="scope.row.devices">
                    <el-table-column property="battery_total" label="充电宝总数" width="110" align="center"></el-table-column>
                    <el-table-column property="battery_count" label="充电宝剩余" width="110" align="center"></el-table-column>
                    <el-table-column property="serial_number" label="序列号" width="200" align="center"></el-table-column>
                  </el-table>
                </div>
                <div slot="reference" class="name-wrapper" style="width-space:nowrap;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;">
                  <span>{{scope.row.devices.length}}台</span>
                </div>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column label="单价" prop="unit_price" align="center"></el-table-column>
          <el-table-column label="时间单位" prop="unit" align="center" width="100">
            <template slot-scope="scope">
              <el-tag type="danger" v-if="scope.row.unit === 0">每半小时</el-tag>
              <el-tag type="success" v-if="scope.row.unit === 1" >每一小时</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="日封顶" prop="day_top" align="center"></el-table-column>
          <!-- <el-table-column label="初始总配宝数" prop="unit_price" align="center"></el-table-column> -->
          <el-table-column label="所属公司" prop="group.name" align="center" width="120" v-if="groupID===1">
            <template slot-scope="scope">
              <el-popover trigger="hover" placement="top">
                <div>
                  <el-descriptions class="margin-top" title="公司信息" :column="2" border>
                    <el-descriptions-item>
                      <template slot="label">
                        <i class="el-icon-user"></i>
                        公司名
                      </template>
                      {{scope.row.group.name}}
                    </el-descriptions-item>
                    <el-descriptions-item>
                      <template slot="label">
                        <i class="el-icon-office-building"></i>
                        公司地址
                      </template>
                      {{scope.row.group.address}}
                    </el-descriptions-item>
                    <el-descriptions-item>
                      <template slot="label">
                        <i class="el-icon-star-on"></i>
                        总分
                      </template>
                      {{scope.row.group.comprehensive_score}}
                    </el-descriptions-item>
                    <el-descriptions-item>
                      <template slot="label">
                        <i class="el-icon-star-off"></i>
                        月度评分
                      </template>
                      {{scope.row.group.month_score}}
                    </el-descriptions-item>
                  </el-descriptions>
                </div>
                <div slot="reference" class="name-wrapper" style="width-space:nowrap;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;">
                  <span>{{scope.row.group.name}}</span>
                </div>
              </el-popover>
              
            </template>
          </el-table-column>
          <el-table-column label="代理商" prop="agent" align="center" width="100">
            <template slot-scope="scope">
              <span v-if="scope.row.agent">{{scope.row.agent.name }}</span>
            </template>
          </el-table-column>
          <el-table-column label="投放点省份" prop="province_name" align="center"></el-table-column>
          <el-table-column label="投放点城市" prop="city_name" align="center"></el-table-column>
          
          <el-table-column label="地址" prop="address" align="center" width="200">
            <template slot-scope="scope">
              <el-popover trigger="hover" placement="top">
                <p>投放点: {{ scope.row.address }}</p>
                <div slot="reference" class="name-wrapper" style="width-space:nowrap;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;">
                  <span>{{scope.row.address}}</span>
                </div>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column label="市场负责人" prop="market" align="center" width="100"></el-table-column>
          <el-table-column label="场所负责人" prop="realname" align="center" width="120"></el-table-column>
          <el-table-column label="联系方式" prop="phone" align="center" width="120"></el-table-column>
          <!-- <el-table-column label="信用代码" prop="credit_code" align="center" width="170"></el-table-column> -->

          <el-table-column label="操作" width="" align="center" fixed="right">
            <template slot-scope="scope">
              <div
                style="
                  width: 100%;
                  display: inline-flex;
                  justify-content: space-between;
                "
              >
                <el-link style="color: #409eff;" v-if="(scope.row.status !==2 || scope.row.status !==3)" @click="handleDetail(scope.row.id)">
                  <el-tooltip class="item" effect="dark" content="查看详情" placement="top-start">
                    <i class="el-icon-view" style="font-size:16px;"></i>
                  </el-tooltip>
                </el-link>
                <el-link style="color: #409eff;margin-left:10px;margin-right:10px;" v-if="(scope.row.status !==2 || scope.row.status !==3) && admin_permission.indexOf('update')>-1" @click="editTable(scope.row.id)">
                  <el-tooltip class="item" effect="dark" content="修改" placement="top-start">
                    <i class="el-icon-edit" style="font-size:16px;"></i>
                  </el-tooltip>
                </el-link>
                <el-dropdown @command="command($event,scope.row)">
                  <span class="el-dropdown-link" style="color: #FFC14B">
                    <el-tooltip class="item" effect="dark" content="更多操作" placement="top-start">
                      <i class="el-icon-arrow-down" style="font-size:16px;"></i>
                    </el-tooltip>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="delete" v-if="admin_permission.indexOf('deleted')>-1">删除商铺</el-dropdown-item>
                    <el-dropdown-item command="price" v-if="admin_permission.indexOf('update')>-1 && scope.row.status === 1">设置价格</el-dropdown-item>
                    <el-dropdown-item command="percent" v-if="admin_permission.indexOf('update')>-1 && scope.row.status === 1">修改分润</el-dropdown-item>
                    <el-dropdown-item command="appointment" divided v-if="(scope.row.status===0) && admin_permission.indexOf('examine')>-1">通过审核</el-dropdown-item>

                    <el-dropdown-item command="refuse" v-if="(scope.row.status===0) && admin_permission.indexOf('examine')>-1">拒绝通过</el-dropdown-item>
                    <el-dropdown-item command="deduction" v-if="groupID===1">扣量</el-dropdown-item>
                    <el-dropdown-item command="media">推广内容</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div style="width:100%;display:flex;justify-content: flex-end;box-sizing: border-box;padding: 10px;">
          
          <!-- <div class="word">可提现总金额:  <span>{{total_amount}}</span></div> -->
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page.sync="params.page"
            :hide-on-single-page="false"
            :small="true"
            :page-size="params.page_count"
            layout="total, prev, pager, next,jumper"
            :total="total">
          </el-pagination>
        </div>
      </el-main>
    </div>
    <el-dialog
      title="扣量操作"
      :visible.sync="dialogDeductionVisible"
      width="30%"
      :before-close="handleClose">
      <div>
        <div style="display: flex;justify-content: flex-start;align-items: center;margin-top:20px;">
          <div style="width:120px;">扣量保护期限 ：</div>
          <el-switch
            v-model="deductionShow"
            active-color="#13ce66"
            inactive-color="#ccc"
            @change="deductionChange">
          </el-switch>
        </div>
        <div style="display: flex;justify-content: flex-start;align-items: center;margin-top:20px;" v-if="deduction === 1">
          <div style="width:120px;">扣量保护期限 ：</div>
          <el-input style="width:200px;" v-model="deduction_protect" placeholder="请输入扣量保护期限"></el-input>
        </div>
        <div style="display: flex;justify-content: flex-start;align-items: center;margin-top:20px;" v-if="deduction === 1">
          <div style="width:120px;">扣量比例(%) ：</div>
          <el-input style="width:200px;" v-model="deduction_percent" placeholder="请输入扣量比例">
            <template slot="append">%</template>
          </el-input>
        </div>
        <div style="display: flex;justify-content: flex-start;align-items: center;margin-top:20px;" v-if="deduction === 1">
          <div style="width:120px;">扣量起订金额 ：</div>
          <el-input style="width:200px;" v-model="deduction_begin" placeholder="请输入扣量起订金额"></el-input>
        </div>
        <div style="display: flex;justify-content: flex-start;align-items: center;margin-top:20px;" v-if="deduction === 1">
          <div style="width:120px;">扣量扣款比例 ：</div>
          <el-input style="width:200px;" v-model="deduction_price_percent" placeholder="请输入扣量扣款比例"></el-input>
        </div>
      </div>
      
      <span slot="footer" class="dialog-footer">
        <el-button @click="deductionCancel()">取 消</el-button>
        <el-button type="primary" @click="deductionConfirm()">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="设备详情" v-if="dialogRefuseVisible" :visible.sync="dialogRefuseVisible">
      <div style="margin-bottom: 20px;">拒绝理由</div>
      <el-input v-model="reason" placeholder="请输入拒绝理由"></el-input>
      <div style="display: flex;justify-content: space-around;width:200px;margin:30px auto 10px auto;">
        <el-button>取消</el-button>
        <el-button type="primary" @click="refuse">确定</el-button>
      </div>
      
    </el-dialog>
    <!-- 查看详情信息 -->
    <el-dialog title="设备详情" v-if="dialogTableVisible" :visible.sync="dialogTableVisible">
      <store-list :id="storeID"></store-list>
    </el-dialog>
    <el-drawer
      title="更改投放点信息"
      :visible.sync="editDrawer"
      :direction="direction"
      :before-close="handleClose"
    >
      <edit-comp v-if="editDrawer" @close="handleClose" @success="handleDrawerSuccess" :id="editID" ref="newForm"></edit-comp>
    </el-drawer>

    <!-- 添加规则 -->
    <el-drawer
      title="添加投放点"
      :visible.sync="addDrawer"
      :direction="direction"
      :before-close="handleClose"
    >
      <add-comp
        @close="handleClose"
        @success="handleDrawerSuccess"
        @clear="clearContent"
        ref="newForm"
        v-if="addDrawer"
      ></add-comp>
    </el-drawer>
    
    <el-dialog
      title="修改分润比例"
      :visible.sync="dialogPerVisible"
      width="30%"
      :before-close="handleClose">
      <div style="margin-top:20px;">分润</div>
      <el-input v-model="devided" placeholder="请输入分润比例" style="width:300px;margin-top:10px;">
        <template slot="append">%</template>
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel()">取 消</el-button>
        <el-button type="primary" @click="confirmPer()">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="修改价格"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose">
      <div>单位</div>
      <el-select v-model="unit" clearable filterable placeholder="请选择单位" style="width:300px;margin-top:10px;">
        <el-option
          v-for="item in unitList"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
      <div style="margin-top:20px;">单价</div>
      <el-input v-model="unit_price" placeholder="请输入单价" style="width:300px;margin-top:10px;"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel()">取 消</el-button>
        <el-button type="primary" @click="confirm()">确 定</el-button>
      </span>
    </el-dialog>
    
    <el-dialog
      title="推广内容"
      :visible.sync="dialogMediaVisible"
      :before-close="handleClose">
      <div style="display: flex;justify-content: flex-start;">
        <div>
          <div style="font-size:14px;margin-bottom: 20px;padding-left:10px;">请上传推广图片 </div>
          <upload-qiniu @success="handlePicSuccess" @remove="handlePicRemove" :num="1" path="store" :multiple="true" :images="storeImageUrl" style=" box-shadow: 3px 3px 8px 3px rgba(0, 0, 0, 0.1);"></upload-qiniu>
        </div>
        <div style="margin-left: 40px;">
          <div style="font-size:15px;font-weight: 600;">推广标题 <span style="font-size:14px;color:darkgray;font-weight:400;margin-left:5px;">最大字数位12个字</span></div>
          <el-input v-model="mediaTitle" placeholder="最多输入12个字" style="width:300px;margin-top:10px;" maxlength="12"></el-input>
          <div style="font-size:15px;font-weight: 600;margin-top: 10px;">推广内容 <span style="font-size:14px;color:darkgray;font-weight:400;margin-left:5px;">最大字数位37个字</span></div>
          <el-input type="textarea" v-model="mediaContent" placeholder="最多输入37个字" autosize maxlength="37" style="width:300px;margin-top:10px;font-size:14px;"></el-input>
        </div>
        <div></div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel()">取 消</el-button>
        <el-button type="primary" @click="confirmMedia()">确 定</el-button>
      </span>
    </el-dialog>
  </section>
</template>

<script>
  import { getList, addData,editData,getTypeList,editPrice,editDevided,deleteData,storeAppointment,storeRefuse,updateDeduction,getMedia,editMedia} from "@/api/store.js";
  import { getProvince,getCity,getArea} from "@/api/public.js";
  import { getGroups} from "@/api/system.js";

  import UploadQiniu from "@/components/tool/lib/upload/index.vue";
  import addComp from "./components/add.vue";
  import editComp from "./components/edit.vue";
  import storeList from "@/views/components/storeList.vue";

  let page_name = 'place_list'
  export default {
    name: "App",
    components:{addComp,editComp,storeList,UploadQiniu},
    data() {
      return {
        reason:'',
        admin_permission:[],
        dialogVisible:false,
        dialogPerVisible:false,
        dialogTableVisible:false,
        dialogRefuseVisible:false,
        dialogMediaVisible:false,//推广内容弹窗
        dialogDeductionVisible:false,//扣量弹窗
        mediaID:'',//推广内容弹窗ID
        mediaTitle:'',//推广标题
        mediaContent:'',//推广内容
        deductionID:'',
        deductionShow:false,
        deduction:0,
        deduction_protect :30,
        deduction_percent :10,
        deduction_begin:0,
        deduction_price_percent:10,
        refuseId:'',
        storeImageUrl:[],
        mediaList:[],
        status: [
          {id:"",label:"全部"},
          {id:0,label:"审核中"},
          {id:1,label:"审核通过"},
          {id:2,label:"审核失败"},
          {id:3,label:"失效点位"},
        ],
        treeCurrentNode:"",
        storeTypeOptions:[],
        sizeOptions:[
          {id:0,label:"普通"},
          {id:1,label:"中型"},
          {id:2,label:"大型"},
          {id:3,label:"超大型"}
        ],
        layTypeOptions:[
          {id:0,label:"独家"},
          {id:1,label:"共存"},
        ],
        unitList: [{
          value: 0,
          label: '每半小时'
        }, {
          value: 1,
          label: '每一小时'
        }],
        unit:'',
        unit_price:'',
        unitID:'',
        storeID:'',
        devidedID:'',
        provinceList:[],
        cityList:[],
        areaList:[],
        province_id:"",
        city_id:"",
        params: {
          page: 1,
          page_count: 10,
          username:'',
          gender:'',
          mobile:'',
          status:'',
          nickname:'',
          province_id:'',
          city_id:'',
          area_id:'',
          group_id:'',
          store_type:'',
          lay_type:'',
          store_size:'',
          market:''
        },
        groupList:[],
        groupID:'',
        table:[],
        devided:0,
        service: [],
        editID: '',
        total: 0,
        outerVisible: false,
        innerVisible: false,
        addDrawer: false,
        editDrawer: false,
        direction: "rtl",
        addTradeParams: {
          name: "",
          parent_id: 0
        },
        visible: false,
        scopeForm:{
          id:'',
          sort: ''
        }
      };
    },

    methods: {
      getTableData() {
        getList(this.params).then(res=>{
          if(res.code === 200){
            this.table = res.data.data
            console.log(this.table)
            this.params.page = res.data.current_page
            this.params.page_count = res.data.per_page
            this.total = res.data.total
          }
        })
      },
      searchData(){
        this.params.page=1
        this.getTableData()
      },
      searchType(){
        getTypeList().then(res=>{
          if(res.code === 200){
            this.storeTypeOptions = res.data
            console.log(this.storeTypeOptions)
          }
        })
      },
      provinceClick(){
        let that = this
        this.provinceList.forEach(item => {
          if(item.id == that.params.province_id ){
            that.province_id = item.province_id
            console.log(that.province_id)
          }
        });
        getCity(that.province_id).then(res=>{
          if(res.code === 200){
            console.log(res.data)
            this.cityList = res.data
          }
        })
      },
      cityClick(){
        let that = this
        this.cityList.forEach(item => {
          if(item.id == that.params.city_id ){
            that.city_id = item.city_id
            console.log(that.city_id)
          }
        });
        getArea(that.city_id).then(res=>{
          if(res.code === 200){
            console.log(res.data)
            this.areaList = res.data
          }
        })
      },
      command(e,item){
          console.log(e)
        if(e === 'refuse'){
          this.refuseId = item.id
          this.dialogRefuseVisible =true
        }
        if(e === 'appointment'){
          storeAppointment(item.id).then(res=>{
            if(res.code===200){
              this.getTableData()
              this.$notify({
                title: '成功',
                message: '已通过',
                type: 'success'
              });
            }
          })
        }
        if(e === 'price'){
          this.dialogVisible=true
          this.unitID = item.id
        }
        if(e === 'percent'){
          this.dialogPerVisible=true
          this.devidedID = item.id
          this.devided = parseFloat(item.devided)*100
        }
        if(e === 'media'){
          this.dialogMediaVisible=true
          this.mediaID = item.id
          getMedia(this.mediaID).then(res=>{
            if(res.code===200){
              this.mediaList = res.data
              console.log(this.mediaList)
            }
          })
        }
        if(e === 'delete'){
          this.$confirm('删除后无法恢复，请慎重考虑，确认删除数据吗？')
          .then(_ => {
            deleteData(item.id).then(res=>{
              this.getTableData()
              this.$notify({
                title: '成功',
                message: '删除成功',
                type: 'success'
              });
            })
          })
          .catch(_ => {});
        }
        if(e === 'deduction'){
          console.log(item)
          this.dialogDeductionVisible = true
          this.deductionID = item.id
          this.deduction = item.deduction
          if(this.deduction==1){
            this.deductionShow = true
          }else{
            this.deductionShow = false
          }
          this.deduction_protect = item.deduction_protect
          this.deduction_begin = item.deduction_begin
          this.deduction_percent = item.deduction_percent
          this.deduction_price_percent = item.deduction_price_percent
          // getCode({group_id:item.id}).then(res=>{
          //   if(res.code === 200){
          //     this.code = res.data
          //   }
          // })
        }
      },
      deductionChange(e){
        console.log(e)
        if(e == true){
          this.deduction = 1
        }else{
          this.deduction = 0
        }
      },
      deductionCancel(){
        this.dialogDeductionVisible = false
      },
      deductionConfirm(){
        let params = {
          'deduction':this.deduction,
          'deduction_protect':this.deduction_protect,
          'deduction_begin':this.deduction_begin,
          'deduction_percent':this.deduction_percent,
          'deduction_price_percent':this.deduction_price_percent
        }
        updateDeduction(this.deductionID,params).then(res=>{
          if(res.code === 200){
            console.log(1)
            this.$message({
                message: "修改成功",
                type: "success"
            });
            this.dialogDeductionVisible = false
            this.getTableData()
          }
        })
      },
      refuse(){
        if(this.reason === ''){
          this.$message({
            message: "请输入拒绝理由",
            type: "success"
          });
          return false
        } 
        storeRefuse(this.refuseId,{reason:this.reason}).then(res=>{
          if(res.code === 200){
            this.dialogRefuseVisible = false
            this.getTableData()
            this.$notify({
              message: '已拒绝',
            });
          }
        })
      },
      cancel(){
        this.dialogVisible=false
        this.dialogRefuseVisible=false
        this.dialogPerVisible=false
      },
      confirm(){
        let _this = this
        this.dialogVisible=false
        let unitParams ={}
        unitParams.unit=this.unit
        unitParams.unit_price=this.unit_price
        editPrice(this.unitID,unitParams).then((res) => {
          if (res.code === 200) {
            _this.$message({
              message: "修改成功",
              type: "success"
            });
            _this.getTableData()
          }
        });
      },
      
      confirmPer(){
        let _this = this
        this.dialogPerVisible=false
        let unitParams ={}
        unitParams.devided=this.devided
        editDevided(this.devidedID,unitParams).then((res) => {
          if (res.code === 200) {
            _this.$message({
              message: "修改成功",
              type: "success"
            });
            _this.getTableData()
          }
        });
      },
      confirmMedia(){
        console.log(this.storeImageUrl)
        console.log(this.mediaTitle)
        console.log(this.mediaContent)
        let _this = this
        this.dialogMediaVisible=false
        let mediaParams ={}
        mediaParams.image=this.storeImageUrl[0]
        mediaParams.title=this.mediaTitle
        mediaParams.content=this.mediaContent
        editMedia(this.mediaID,mediaParams).then(res=>{
          if(res.code===200){
            _this.$message({
              message: "修改成功",
              type: "success"
            });
            _this.getTableData()
          }
        })
      },
      writeScope(id){
        console.log(id)
        this.scopeForm.id = id
        let tmp = [];
        this.service.forEach((value,key)=>{
          tmp[key] = value
          if (id === value.id){
            this.scopeForm.sort = parseInt(value.sort)
            tmp[key].write_scope = true
          }
        })
        this.service = tmp;
      },

      scopeChange(){
        editData(this.scopeForm.id,{sort:this.scopeForm.sort}).then(res=>{
          if (res.code === 200){
            this.searchSet()
          }
        })
      },

      //选择TREE
      showTreeWorker(id, pid) {
        console.log(id)
        if(id === 3){
          this.params.deleted = 1;
        }else{
          this.params.deleted = '';
          this.params.status = id;
        }
          
          this.getTableData();
      },

      handleDetail(e){
        console.log(e)
        this.storeID=e
        this.dialogTableVisible = true
      },
      // 打开添加
      handleOpenAdd() {
        this.addDrawer = true;
      },
      // 关闭添加/修改
      handleClose() {
        this.$confirm("确认关闭？")
          .then((_) => {
            this.addDrawer = false;
            this.editDrawer = false;
            this.dialogPerVisible=false
            this.dialogDeductionVisible = false
            this.dialogMediaVisible = false
          })
          .catch((_) => {});
      },

      editTable(id){
        this.editID = id
        this.editDrawer = true
      },
      // 添加/修改成功
      handleDrawerSuccess() {
        this.getTableData()
        this.addDrawer = false;
        this.editDrawer = false;
        // this.searchSet()
        this.$refs.newForm.$refs.form.resetFields();
      },
      // 清空子组件
      clearContent() {
        this.$refs.newForm.$refs.form.resetFields();
      },
      
      // 上传图片删除、缓存、预览
      handlePicSuccess(e) {
        this.storeImageUrl = e;
      },
      handlePicRemove(e) {
        this.storeImageUrl = [];
      },
      // 分页
      prevpage() {
        this.params.page -= 1;
        this.getTableData();
      },
      nextpage() {
        this.params.page += 1;
        this.getTableData();
      },
      handleCurrentChange(val) {
        this.params.page = val;
        this.getTableData();
      },
    },
    mounted() {
      this.admin_permission = this.$store.state.permission.permissionArr[page_name]
      this.getTableData();
      this.searchType();
      
      this.groupID = this.$store.state.user.userData.group_id
      getGroups().then(res=>{
        if(res.code === 200){
          this.groupList = res.data
        }
      })
      getProvince().then(res=>{
        if(res.code === 200){
          this.provinceList = res.data
        }
      })
    }
  };
</script>

<style scoped lang="scss">
  @import "@/static/default";

  .container {
    width: 100%;
    min-height: 100%;
    background-color: #fff !important;
    padding: 20px 32px;
    padding-bottom:0;
    display: inline-flex;

    .table {
      margin-top: 24px !important;
    }

    .toolbar {
      background: #fff;
      width: 100%;
      padding: 16px 32px 26px 0;
    }
  }

  .control-box{
    width: 100%;
    display:flex;
    flex-wrap: wrap;
    &-input{
      width: 150px;
    }
  }
  .el-main {
    padding: 0;
  }

  .demo-table-expand {
    font-size: 0;
  }

  .demo-table-expand label {
    width: 90px;
    color: #99a9bf;
  }

  .demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
  }
</style>
<style>
  .custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding: 10px 0;
    padding-right: 8px;
  }
  .el-tree-node__expand-icon.is-leaf{
    color: #c0c4cc;
  }
</style>
