<template>
  <section>
    <div class="container">
        <div
          class="worker-tooltips"
          style="display:flex;flex-wrap:wrap; width: 100%;justify-content: flex-start"
        >
          <div class="search-box"><h1>订单列表</h1></div>
          <div class="control-box">
            <el-input class="control-box-input" v-model="params.serial_number" style="width:200px;margin-left:10px;margin-top: 10px;" placeholder="请输入订单号" clearable></el-input>
            <el-select v-model="params.status" placeholder="请选择状态" clearable style="margin-left:10px;width:150px;margin-top: 10px;">
              <el-option
                v-for="item in orderType"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
            <el-select v-model="params.group_id"  placeholder="请选择需搜索公司" filterable clearable style="margin-left:10px;margin-top:10px;" v-if="groupID===1 && params.order_type===0" >
              <!-- <el-option label="请选择需搜索公司">请选择需搜索公司</el-option> -->
              <el-option
                v-for="item in groupList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
            <el-select v-model="params.agent_id"  placeholder="请选择需搜索代理商" filterable clearable style="margin-left:10px;margin-top:10px;" v-if="params.order_type===0" >
              <!-- <el-option label="请选择需搜索公司">请选择需搜索公司</el-option> -->
              <el-option
                v-for="item in agentList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
            <el-select v-model="params.temple_id" placeholder="请选择需搜索寺庙" filterable clearable style="margin-left:10px;margin-top:10px;">
              <!-- <el-option label="请选择需搜索投放点">请选择需搜索投放点</el-option> -->
              <el-option
                v-for="item in templeList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
            <el-select v-model="params.bless_id" placeholder="请选择需搜索供奉类型" filterable clearable style="margin-left:10px;margin-top:10px;">
              <!-- <el-option label="请选择需搜索供奉类型">请选择需搜索供奉类型</el-option> -->
              <el-option
                v-for="item in blessList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
            <el-input class="control-box-input" v-model="params.phone" style="margin-left:10px;margin-top:10px;" placeholder="请输入手机号" clearable></el-input>
            <el-date-picker
              v-model="params.start_date"
              type="date" style="margin-left:10px;margin-top:10px;"
              format="yyyy-MM-dd 日"
              value-format="yyyy-MM-dd"
              placeholder="请选择开始日期"
              v-if="groupID===1">
            </el-date-picker>
            <el-date-picker
              v-model="params.end_date"
              type="date" style="margin-left:10px;margin-top:10px;"
              format="yyyy-MM-dd 日"
              value-format="yyyy-MM-dd"
              placeholder="请选择结束日期"
              v-if="groupID===1">
            </el-date-picker>
            <el-input class="control-box-input" v-model="params.price" style="margin-left:10px;margin-top:10px;" type="number" placeholder="请输入金额" clearable></el-input>
            <el-input class="control-box-input" v-model="params.market" style="margin-left:10px;margin-top:10px;" placeholder="请输入市场负责人" clearable></el-input>
            <el-button icon="el-icon-search" @click="searchData" style="margin-left:10px;margin-top: 10px;">搜索</el-button>
          </div>
        </div>
      <!--订单列表-->
      <el-table :data="table" class="table" width="1000">
        <!-- 数据展示区 -->
        <el-table-column
          label="订单类型"
          prop="type_name"
          align="center"
          width="140"
          fixed="left"
        ></el-table-column>
        <el-table-column
          label="订单状态"
          prop="status"
          align="center"
          width="150"
          fixed="left"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.status===0">已付款</span>
            <span v-if="scope.row.status===1">已结束</span>
            <span v-if="scope.row.status===2">进行中</span>
          </template>
        </el-table-column>
        <el-table-column label="寺庙" prop="temple_name"  align="center">
        </el-table-column>
        <el-table-column
          label="付款金额"
          prop="amount"
          align="center"
          width="140"
        ></el-table-column>
        <el-table-column
          label="创建时间"
          prop="updated_at"
          align="center"
        ></el-table-column>
        <el-table-column
          label="订单号"
          prop="serial"
          align="center"
        ></el-table-column>
        <!-- <el-table-column label="所属公司" prop="group.name" align="center" width="150" v-if="groupID===1">
          <template slot-scope="scope">
            <el-popover trigger="hover" placement="top" v-if="scope.row.group">
              <p v-if="scope.row.agent">所属公司: {{ scope.row.group.name }}</p>
              <div slot="reference" class="name-wrapper" style="width-space:nowrap;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;">
                <span>{{scope.row.group.name}}</span>
              </div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column
          label="所属代理商"
          prop="agent"
          align="center"
          width="180"
          v-if="params.order_type===0"
        >
          <template slot-scope="scope">
            <el-popover trigger="hover" placement="top" v-if="scope.row.agent">
              <p v-if="scope.row.agent">代理商: {{ scope.row.agent.name }}</p>
              <div slot="reference" class="name-wrapper" style="width-space:nowrap;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;">
                <span>{{scope.row.agent.name}}</span>
              </div>
            </el-popover>
          </template>
        </el-table-column> -->
        <!-- <el-table-column
          label="借用类型"
          prop="type_zh"
          align="center"
          width="100"
          v-if="params.order_type===0"
        >
        </el-table-column> -->
        <el-table-column label="用户姓名" prop="user_id" align="center" >
          <template slot-scope="scope">
            <span>{{scope.row.real_name}}</span>
          </template>
        </el-table-column>
        <el-table-column label="手机号" prop="user_id" align="center" >
          <template slot-scope="scope">
            <span>{{scope.row.phone}}</span>
          </template>
        </el-table-column>

        <!-- <el-table-column label="时间" prop="time" align="center" >
          <template slot-scope="scope">
            <span>{{parseInt(scope.row.time*60)}}分钟</span>
          </template>
        </el-table-column> -->

        <el-table-column label="操作" align="center" width="100" fixed="right">
          <template slot-scope="scope">
            <div

            >
              <el-dropdown @command="command($event,scope.row)">
                  <span class="el-dropdown-link" style="color: #409eff">
                    更多操作
                    <i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="cancelOrder" v-if="admin_permission.indexOf('update')>-1" divided>取消订单</el-dropdown-item>
                  <el-dropdown-item command="remove" v-if="admin_permission.indexOf('deleted')>-1" divided>删除订单</el-dropdown-item>
                  <el-dropdown-item command="pay" divided>订单扣款</el-dropdown-item>
                  <el-dropdown-item command="getAli" v-if="scope.row.channel === 1">支付宝订单查询</el-dropdown-item>
                  <!-- <el-dropdown-item command="pay" v-if="scope.row.status===2&&scope.row.status===3" divided>订单扣款</el-dropdown-item> -->
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </template>
        </el-table-column>
      </el-table>


      <el-dialog title="更改订单状态" width="500px" :visible.sync="statusVisible">
        <el-form :model="formStatus" label-position="top">
          <el-form-item label="订单状态" required>
            <el-select
              v-model="formStatus.status"
              clearable
              placeholder="请选择状态"
            >
              <el-option
                v-for="item in orderType"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>

        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="statusVisible = false" class="cancel-btn">取 消</el-button>
          <el-button type="primary" @click="changeStatus">更改</el-button>
        </div>
      </el-dialog>


      <el-dialog title="订单扣款" width="500px" :visible.sync="payShow">
        
        <div style="display: flex;justify-content: flex-start;align-items: center;margin-top:20px;">
          <div>请选择订单结束时间：</div>
          <el-date-picker
            style="margin-left:10px"
            v-model="end_time"
            type="datetime"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="选择订单结束的日期时间">
          </el-date-picker>
        </div>
        
        <div slot="footer" class="dialog-footer">
          <el-button @click="payShow = false" class="cancel-btn">取 消</el-button>
          <el-button type="primary" @click="cancelPay()">确认</el-button>
        </div>
      </el-dialog>

      <!--分页-->
      <el-col class="toolbar">
        <div class="word">订单总额:  <span>{{total_amount}}</span></div>
        <el-pagination
          layout="total, prev, pager, next, jumper"
          @current-change="handleCurrentChange"
          :current-page.sync="params.page"
          @prev-click="prevpage"
          @next-click="nextpage"
          :page-size="10"
          :total="total"
          style="float: right;flex-shrink: 1;"
        ></el-pagination>
      </el-col>
    </div>
  </section>
</template>

<script>
// import { getCascader } from "@/api/provider.js";
import { getOrder, editData,deleteData,cancelData,getPay,syncAli,getAli,unfreezeAli} from "@/api/order.js";
import { getListSelect} from "@/api/store.js";
import { getGroups} from "@/api/system.js";
import { getTemples,getBless} from "@/api/public.js";

let page_name = 'order_list'
export default {
  name: "App",

  data() {
    return {
      admin_permission:[],
      reasonShow:false,
      payShow:false,
      orderDetailShow:false,
      orderDetail:'',
      reason:'',
      end_time:'',//订单结束时间
      activeName:'',
      outerVisible:false,
      params: {
        page:1,
        // status:'',
        // group_id:'',
        temple_id:'',
        hall_id:0,
        bless_id:"",
        other_bless_id:"",
        // agent_id:'',
        product_id:'',
        type:'',
        keyword:'',
        start_time:'',
        end_time:'',
      },
      formPrice:{id:0,price:''},
      priceVisible:false,
      statusVisible:false,
      formStatus:{id:0,status:''},
      cancelId:'',
      payId:'',//扣款订单id
      cascaderOpthion:[],
      cascaderValue:'',
      table: [],
      total: 0,
      total_amount:0,
      groupList:[],
      templeList:[],
      blessList:[],
      agentList:[],
      groupID:'',
      showBack: false,
      orderType:[
        {
          value: 0,
          label: "已付款",
        },
        {
          value: 1,
          label: "已结束",
        },
        {
          value: 2,
          label: "进行中",
        },
      ],
    };
  },

  mounted() {
    this.admin_permission = this.$store.state.permission.permissionArr[page_name]
    this.getTable();
    this.groupID = this.$store.state.user.userData.group_id
    
    // getGroups().then(res=>{
    //   if(res.code === 200){
    //     this.groupList = res.data
    //   }
    // })
    getTemples().then(res=>{
      if(res.code === 200){
        console.log(res.data)
        this.templeList = res.data
      }
    })
    getBless().then(res=>{
      if(res.code === 200){
        console.log(res)
        this.blessList = res.data
      }
    })
    // getAgentListSelect().then(res=>{
    //   if(res.code === 200){
    //     this.agentList = res.data
    //   }
    // })
  },

  methods: {
    // 获取订单列表
    getTable() {
      getOrder(this.params).then((res) => {
        if (res.code === 200) {
          console.log(res.data.data)
          this.table = res.data.data
          this.total = res.data.total;
          this.total_amount = res.data.total_amount
        }
      });
    },
    handleClick(tab, event) {
      if(this.activeName === 'default'){
        this.params.order_type = 0
        this.params.page = 1
      }
      if(this.activeName === 'cash'){
        this.params.order_type = 1
        this.params.page = 1
      }
      this.getTable()
    },
    searchData(){
      this.params.page=1
      console.log(this.params)
      this.getTable()
    },
    endAliOrder(){
      this.$confirm('结束订单后无法恢复，确认结束订单吗？').then(_ => {
        let id = this.orderDetail.order.id
        let amount = this.orderDetail.alipay_fund_auth_operation_detail_query_response.rest_amount
        unfreezeAli(id,{amount:amount}).then((res) =>{
          if(res.code === 200){
            this.$message.success('解冻成功')
            this.getTable()
          }
        })
      })
      .catch(_ => {});
    },
    command(e,row){
      if(e === 'cancelOrder'){
        this.cancelId = row.id
        console.log(row)
        this.reasonShow = true
      }
      if(e === 'status'){
        this.formStatus.id = row.id
        this.formStatus.status = row.status
        this.statusVisible = true
      }
      if(e === 'pay'){
        this.payId = row.id
        console.log(row)
        this.payShow = true
      }
      if(e === 'syncAli'){
        syncAli(row.id,{}).then((res) =>{
          if(res.code === 200){
            this.$message({
              message: res.msg,
              type:'success'
            })
            this.getTable()
          }
        })
      }
      if(e === 'getAli'){
        this.orderDetailShow = true
        getAli(row.id,{}).then(res=>{
          if(res.code === 200){
            console.log(res);
            this.orderDetail = res.data
            this.orderDetail['order'] = row
          }
        })
      }
      if(e === 'remove'){
        this.$confirm('删除后无法恢复，请慎重考虑，确认删除数据吗？')
          .then(_ => {
            deleteData(row.id).then(res=>{
              this.getTable()
              this.$notify({
                title: '成功',
                message: '删除成功',
                type: 'success'
              });
            })
          })
          .catch(_ => {});
        }
    },
    changeStatus(){
      editData(this.formStatus.id,{'status':this.formStatus.status}).then(res=>{
        if (res.code === 200){
          this.getTable()
          this.$message({
            type: 'success',
            message: '更改成功!'
          });
          this.statusVisible = false
        }
      })
    },
    //取消订单确认
    cancelReason(){
      console.log(this.reason)
      cancelData(this.cancelId,{'reason':this.reason}).then(res=>{
        if (res.code === 200){
          this.getTable()
          this.$message({
            type: 'success',
            message: '已取消!'
          });
          this.reasonShow = false
        }
      })
    },
    //扣款订单确认
    cancelPay(){
      console.log(this.end_time)
      if(!this.end_time){
        this.$message({
          type: 'error',
          message: '请选择时间'
        });
      }else{
        getPay({'id':this.payId,'end_time':this.end_time}).then(res=>{
          if (res.code === 200){
            this.getTable()
            this.$message({
              type: 'success',
              message: '已发起扣款!'
            });
            this.payShow = false
          }
        })
      }
      
    },
    // 查询
    getOrderType(type) {
      console.log(type)
      this.params.status = type;
    },
    getOrderNum(number) {
      this.params.order_number = number;
    },
    handleBack() {
      this.showBack = false;
      this.params = {};
      this.getTable();
    },

    // 删除订单
    handleDelete(index, row) {
      this.$confirm("确认删除？")
        .then((_) => {
          deleteOrder(row.id, { deleted: 1 })
            .then((data) => {
              if (data.code === 200) {
                this.getOrderInfo();
              }
            })
            .catch((error) => {});
        })
        .catch((_) => {});
    },

    // 分页
    prevpage() {
      this.params.page -= 1;
      this.getTable()
    },
    nextpage() {
      this.params.page += 1;
      this.getTable()
    },
    handleCurrentChange(val) {
      this.params.page = val;
      this.getTable()
    },
  }
};
</script>

<style scoped lang="scss">
@import "@/static/default";
.temple {
  width: 100%;
  background-color: white;
  padding: 10px 10px;
  // padding-left: 50px;
}
.order {
  width: 100%;
  background-color: white;
  padding: 10px 50px;
  // padding-left: 50px;
}
.container {
  background-color: #fff !important;
  padding: 0 32px;
  .table {
    margin-top: 24px !important;
  }
  .toolbar {
    background: #fff;
    width: calc(100% + 64px);
    padding: 16px 32px 26px 0;
    margin-left: -32px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .word{
      margin-right:50px;
      font-size:14px;
      span{
        font-size:18px;
      }
    }
  }
  .search-box{
    margin-top:20px;
    padding-left:20px;
  }
  .control-box{
    width: 100%;
    display:flex;
    flex-wrap: wrap;
    padding-left:20px;
    padding-top:10px;
    &-input{
      width: 150px;
    }
  }
}

.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
</style>
<style>
  .el-table .warning-row {
    background: rgb(250, 236, 216);
  }

  .el-table .success-row {
    background: rgb(225, 243, 216);
  }

  .el-table .danger-row {
    background: rgb(253, 226, 226);;
  }

  .el-table .info-row {
    background: rgb(233, 233, 235);
  }
</style>