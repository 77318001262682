<template>
  <section>
    <div class="container">
      <el-row :gutter="20" :span="24">
        <el-col :span="span">
          <div class="grid-content">
            <div class="title">
              订单总额
            </div>
            <div class="number">￥{{total_data.order_amount.total}}</div>
            <div class="week">
              <span>周同比</span>
              <span style="margin-left:10px;">
                {{total_data.order_amount.week_compare}}%
                <i v-if="total_data.order_amount.week_compare>=0" class="el-icon-sort-up" style="color:red;font-weight: bolder"></i>
                <i v-else class="el-icon-sort-down" style="color:greenyellow;font-weight: bolder"></i>
              </span>
            </div>
            <div class="daily">
              <span>日同比</span>
              <span style="margin-left:10px;">
                {{total_data.order_amount.day_compare}}%
                <i v-if="total_data.order_amount.day_compare>=0" class="el-icon-sort-up" style="color:red;font-weight: bolder"></i>
                <i v-else class="el-icon-sort-down" style="color:greenyellow;font-weight: bolder"></i>
              </span>
            </div>
            <el-divider></el-divider>
            <div class="day">
              <span>日增长</span>
              <span style="margin-left:10px;">￥{{total_data.order_amount.day}}</span>
            </div>
          </div>
        </el-col>
        <el-col :span="span">
          <div class="grid-content">
            <div class="title">
              订单数
            </div>
            <div class="number">{{total_data.order_count.total}}单</div>
            <div class="week">
              <span>周同比</span>
              <span style="margin-left:10px;">
                {{total_data.order_count.week_compare}}%
                <i v-if="total_data.order_count.week_compare>=0" class="el-icon-sort-up" style="color:red;font-weight: bolder"></i>
                <i v-else class="el-icon-sort-down" style="color:greenyellow;font-weight: bolder"></i>
              </span>
            </div>
            <div class="daily">
              <span>日同比</span>
              <span style="margin-left:10px;">
                {{total_data.order_count.day_compare}}%
                <i v-if="total_data.order_count.day_compare>=0" class="el-icon-sort-up" style="color:red;font-weight: bolder"></i>
                <i v-else class="el-icon-sort-down" style="color:greenyellow;font-weight: bolder"></i>
              </span>
            </div>
            <el-divider></el-divider>
            <div class="day">
              <span>日增长</span>
              <span style="margin-left:10px;">{{total_data.order_count.day}}单</span>
            </div>
          </div>
        </el-col>
        <el-col :span="span">
          <div class="grid-content">
            <div class="title">
              设备数(已投放/仓库中/总数)
            </div>
            <div class="number">{{total_data.device.use}}/{{total_data.device.warehouse}}/{{total_data.device.total}}台</div>
            <div class="week">
              <span>周同比</span>
              <span style="margin-left:10px;">
                {{total_data.device.week_compare}}%
                <i v-if="total_data.device.week_compare>=0" class="el-icon-sort-up" style="color:red;font-weight: bolder"></i>
                <i v-else class="el-icon-sort-down" style="color:greenyellow;font-weight: bolder"></i>
              </span>
            </div>
            <div class="daily">
              <span>日同比</span>
              <span style="margin-left:10px;">
                {{total_data.device.day_compare}}%
                <i v-if="total_data.device.day_compare>=0" class="el-icon-sort-up" style="color:red;font-weight: bolder"></i>
                <i v-else class="el-icon-sort-down" style="color:greenyellow;font-weight: bolder"></i>
              </span>
            </div>
            <el-divider></el-divider>
            <div class="day">
              <span>日增长</span>
              <span style="margin-left:10px;">{{total_data.store.day}}台</span>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="20" :span="24">
        <el-col :span="span">
          <div class="grid-content">
            <div class="title">
              商户数
            </div>
            <div class="number">{{total_data.store.total}}家</div>
            <div class="week">
              <span>周同比</span>
              <span style="margin-left:10px;">
                {{total_data.store.week_compare}}%
                <i v-if="total_data.store.week_compare>=0" class="el-icon-sort-up" style="color:red;font-weight: bolder"></i>
                <i v-else class="el-icon-sort-down" style="color:greenyellow;font-weight: bolder"></i>
              </span>
            </div>
            <div class="daily">
              <span>日同比</span>
              <span style="margin-left:10px;">
                {{total_data.store.day_compare}}%
                <i v-if="total_data.store.day_compare>=0" class="el-icon-sort-up" style="color:red;font-weight: bolder"></i>
                <i v-else class="el-icon-sort-down" style="color:greenyellow;font-weight: bolder"></i>
              </span>
            </div>
            <el-divider></el-divider>
            <div class="day">
              <span>日增长</span>
              <span style="margin-left:10px;">{{total_data.store.day}}家</span>
            </div>
          </div>
        </el-col>
        <el-col :span="span" v-if="groupID<=1">
          <div class="grid-content">
            <div class="title">
              用户数
            </div>
            <div class="number">{{total_data.user.total}}人</div>
            <div class="week">
              <span>周同比</span>
              <span style="margin-left:10px;">
                {{total_data.user.week_compare}}%
                <i v-if="total_data.user.week_compare>=0" class="el-icon-sort-up" style="color:red;font-weight: bolder"></i>
                <i v-else class="el-icon-sort-down" style="color:greenyellow;font-weight: bolder"></i>
              </span>
            </div>
            <div class="daily">
              <span>日同比</span>
              <span style="margin-left:10px;">
                {{total_data.user.day_compare}}%
                <i v-if="total_data.user.day_compare>=0" class="el-icon-sort-up" style="color:red;font-weight: bolder"></i>
                <i v-else class="el-icon-sort-down" style="color:greenyellow;font-weight: bolder"></i>
              </span>
            </div>
            <el-divider></el-divider>
            <div class="day">
              <span>日增长</span>
              <span style="margin-left:10px;">{{total_data.user.day}}人</span>
            </div>
          </div>
        </el-col>
        <el-col :span="span">
          <div class="grid-content" style="min-height:230px!important;">
            <div class="title">
              已投放设备在线数/离线数
            </div>
            <div class="number">{{total_data.device.online}}/{{total_data.device.offline}}台</div>
          </div>
        </el-col>
      </el-row>
      <div>
        <el-row :gutter="20" >
          <el-col :span="12">
            <div  style="background-color: #fff;border-radius:10px;">
              <div  style="margin:10px auto;">
                <el-select v-model="params.group_id"  placeholder="请选择需搜索公司" filterable clearable style="margin-left:30px;margin-top:10px;" v-if="groupID===1">
                  <!-- <el-option label="请选择需搜索公司">请选择需搜索公司</el-option> -->
                  <el-option
                    v-for="item in groupList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                  </el-option>
                </el-select>
                <el-select v-model="params.store_id" placeholder="请选择需搜索投放点" filterable clearable style="margin-left:10px;margin-top:10px;" >
                  <!-- <el-option label="请选择需搜索投放点">请选择需搜索投放点</el-option> -->
                  <el-option
                    v-for="item in storeList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                  </el-option>
                </el-select>
                <el-button icon="el-icon-search" @click="getReport" style="margin-left:10px;margin-top: 10px;">搜索</el-button>
              </div>
              <div class="grid-content" id="mapOption" style="height:600px;width:100%;background-color: white;" ></div>
            </div>
            
          </el-col>
          <el-col :span="12">
            <div style="background-color: #fff;border-radius:10px;">
              <div style="margin:10px auto;">
              <el-select v-model="month_params.group_id"  placeholder="请选择需搜索公司" filterable clearable style="margin-left:30px;margin-top:10px;" v-if="groupID===1">
                <!-- <el-option label="请选择需搜索公司">请选择需搜索公司</el-option> -->
                <el-option
                  v-for="item in groupList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
              <el-select v-model="month_params.store_id" placeholder="请选择需搜索投放点" filterable clearable style="margin-left:10px;margin-top:10px;" >
                <!-- <el-option label="请选择需搜索投放点">请选择需搜索投放点</el-option> -->
                <el-option
                  v-for="item in storeList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
              <el-date-picker
                v-model="month_params.month"
                type="month" style="margin-left:10px;margin-top:10px;"
                format="yyyy-MM"
                value-format="yyyy-MM"
                placeholder="请选择月份">
              </el-date-picker>
              <el-button icon="el-icon-search" @click="getReportMonth" style="margin-left:10px;margin-top: 10px;">搜索</el-button>
            </div>
            <div class="grid-content" id="childOption" style="height:600px;width:100%;background-color: white;" ></div>
            </div>
          </el-col>
          
        </el-row>
        <el-row :gutter="20" :span="24" v-if="groupID===1">
          <el-col :span="12">
            <div class="grid-content" id="goodsOption" style="height:600px;width:100%;background-color: white;" ></div>
          </el-col>
          <el-col :span="12">
            <div class="grid-content" id="genderOption" style="height:600px;width:100%;background-color: white;" ></div>
          </el-col>
        </el-row>
      </div>
    </div>
  </section>
</template>

<script>
  let _this
  var emphasisStyle = {
    itemStyle: {
      shadowBlur: 10,
      shadowColor: 'rgba(0,0,0,0.3)'
    }
  };
  const colors = ['#5470C6', '#33ccff', '#91CC75'];
  import {index,getReport,getReportMonth,getReportStore,getReportGroup} from "@/api/public"
  import { getListSelect} from "@/api/store.js";
  import { getGroups} from "@/api/system.js";
  export default {
    name: "App",
    data() {
      return {
        groupList:[],
        storeList:[],
        total_data:{
          "device":{"use":0,"total":0,"day":0,"week_compare":0,"day_compare":0},
          "store":{"total":0,"day":0,"week_compare":0,"day_compare":0},
          "user":{"total":0,"day":0,"week_compare":0,"day_compare":0},
          "order_count":{"total":0,"day":0,"week_compare":0,"day_compare":0},
          "order_amount":{"total":"0.00","day":"0.00","week_compare":0,"day_compare":0}
        },
        params:{
          store_id:'',
          group_id:''
        },
        month_params:{
          store_id:'',
          group_id:'',
          month:''
        },
        order_statistic_all_data:[],
        order_statistic:{
          title:{
            x: 'center', 
            text:"年度月营收对比"
          },
          color: colors,
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross'
            },
            formatter: function (params) {
              let res = `<div style='padding:10px;display:flex;flex-wrap:wrap;max-width:160px;'>
                  <span style='font-weight:bold'>${params[0].axisValue}</span><br/>
                  <div style='display:inline-flex;justify-content: space-between;width:100%;'>
                    <div style='font-size: 12px;font-weight:bold;display:inline-flex;'>
                      订单总额：
                    </div>
                    <div style='font-size: 12px;text-align:right;'>
                      ${_this.order_statistic_all_data[params[0].dataIndex]}
                    </div>
                  </div>`;
              params.forEach((value,key) => {
                res += `<div style='display:inline-flex;justify-content: space-between;width:100%;'>
                    <div style='font-size: 12px;font-weight:bold;display:inline-flex;align-items: center;'>
                      <div style='width:8px;height:8px;border-radius:8px;margin-right:5px;background-color:${value.color}'></div>${value.seriesName}：
                    </div>
                    <div style='font-size: 12px;text-align:right;'>
                      ${value.value}
                    </div>
                  </div>`
              });
              res += `</div>`
              return res
            }
          },
          grid: {
            left: '1%',
            right: '1%',
            bottom: '3%',
            top: '15%',
            containLabel: true
          },
          toolbox: {
            left:"4%",
            feature: {
              dataView: { show: true, readOnly: false },
              magicType: { show: true, type: ['line', 'bar'] },
              restore: { show: true },
              saveAsImage: { show: true }
            }
          },
          legend: {x:'right', padding:[6,0,0,0],  data: ['订单数', '正常订单总额','超时订单总额'] },
          xAxis: [
            {
              type: 'category',
              axisTick: {
                alignWithLabel: true
              },
              data: []
            }
          ],
          yAxis: [
          {
              type: 'value',
              name: '订单额',
              position: 'right',
              alignTicks: true,
              axisLine: {
                show: true,
                lineStyle: {
                  color: colors[0]
                }
              },
              axisLabel: {
                formatter: '￥{value}'
              }
            },
            
            {
              type: 'value',
              name: '订单数',
              position: 'left',
              alignTicks: true,
              axisLine: {
                show: true,
                lineStyle: {
                  color: colors[2]
                }
              },
              axisLabel: {
                formatter: '{value} 单'
              }
            },
            
          ],
          series: [
            {
              name: '正常订单总额',
              type: 'bar',
              stack: 'one',
              emphasis: emphasisStyle,
              data: []
            },
            {
              name: '超时订单总额',
              type: 'bar',
              stack: 'one',
              emphasis: emphasisStyle,
              data: []
            },
            {
              name: '订单数',
              type: 'line',
              yAxisIndex: 1,
              data: []
            }
          ]
        },
        child_statistic_all_data:[],
        child_statistic:{
          title:{
            x: 'center', 
            text:"月度日营收对比"
          },
          color: colors,
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross'
            },
            formatter: function (params) {
              let res = `<div style='padding:10px;display:flex;flex-wrap:wrap;max-width:160px;'>
                  <span style='font-weight:bold'>${params[0].axisValue}</span><br/>
                  <div style='display:inline-flex;justify-content: space-between;width:100%;'>
                    <div style='font-size: 12px;font-weight:bold;display:inline-flex;'>
                      订单总额：
                    </div>
                    <div style='font-size: 12px;text-align:right;'>
                      ${_this.child_statistic_all_data[params[0].dataIndex]}
                    </div>
                  </div>`;
              params.forEach((value,key) => {
                res += `<div style='display:inline-flex;justify-content: space-between;width:100%;'>
                    <div style='font-size: 12px;font-weight:bold;display:inline-flex;align-items: center;'>
                      <div style='width:8px;height:8px;border-radius:8px;margin-right:5px;background-color:${value.color}'></div>${value.seriesName}：
                    </div>
                    <div style='font-size: 12px;text-align:right;'>
                      ${value.value}
                    </div>
                  </div>`
              });
              res += `</div>`
              return res
            }
          },
          grid: {
            left: '1%',
            right: '1%',
            bottom: '3%',
            top: '15%',
            containLabel: true
          },
          toolbox: {
            left:"4%",
            feature: {
              dataView: { show: true, readOnly: false },
              magicType: { show: true, type: ['line', 'bar'] },
              restore: { show: true },
              saveAsImage: { show: true }
            }
          },
          legend: {x:'right', padding:[6,0,0,0],  data: ['订单数', '正常订单总额','超时订单总额'] },
          xAxis: [
            {
              type: 'category',
              axisTick: {
                alignWithLabel: true
              },
              data: []
            }
          ],
          yAxis: [
          {
              type: 'value',
              name: '销售额',
              position: 'right',
              alignTicks: true,
              axisLine: {
                show: true,
                lineStyle: {
                  color: colors[0]
                }
              },
              axisLabel: {
                formatter: '￥{value}'
              }
            },
            {
              type: 'value',
              name: '订单数',
              position: 'left',
              alignTicks: true,
              axisLine: {
                show: true,
                lineStyle: {
                  color: colors[2]
                }
              },
              axisLabel: {
                formatter: '{value} 单'
              }
            },
            
          ],
          series: [
          {
              name: '正常订单总额',
              type: 'bar',
              stack: 'one',
              emphasis: emphasisStyle,
              data: []
            },
            {
              name: '超时订单总额',
              type: 'bar',
              stack: 'one',
              emphasis: emphasisStyle,
              data: []
            },
            {
              name: '订单数',
              type: 'line',
              yAxisIndex: 1,
              data: []
            }
          ]
        },
        goods_statistic:{
          title:{
            x: 'center', 
            text:"子公司营收总额"
          },
          color: colors,
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross'
            }
          },
          grid: {
            left: '10%',
            right: '5%',
            bottom: '3%',
            containLabel: false
          },
          toolbox: {
            
          },
          legend: {x:'right', padding:[6,0,0,0],  data: ['销售额'] },
          xAxis: {
            max: 'dataMax'
          },
          yAxis: {
            type: 'category',
            data: [],
            inverse: true,
            axisLabel: {
              interval: 0,
              rotate: 30
            },
            animationDuration: 300,
            animationDurationUpdate: 300,
            max: 9 // only the largest 3 bars will be displayed
          },
          series: [
            {
              realtimeSort: true,
              name: '销售额',
              type: 'bar',
              data: [],
              label: {
                show: false,
                position: 'center',
                valueAnimation: true
              }
            },

          ],
          animationDuration: 0,
          animationDurationUpdate: 3000,
          animationEasing: 'linear',
          animationEasingUpdate: 'linear'
        },
        gender_statistic:{
          title:{
            x: 'center', 
            text:"场所营收总额"
          },
          color: colors,
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross'
            }
          },
          grid: {
            left: '10%',
            right: '5%',
            bottom: '3%',
            containLabel: false
          },
          toolbox: {
            
          },

          xAxis: {
            max: 'dataMax'
          },
          yAxis: {
            type: 'category',
            data: [],
            inverse: true,
            axisLabel: {
              interval: 0,
              rotate: 30
            },
            animationDuration: 300,
            animationDurationUpdate: 300,
            max: 9 // only the largest 3 bars will be displayed
          },
          series: [
            {
              realtimeSort: true,
              name: '销售额',
              type: 'bar',
              data: [],
              label: {
                show: false,
                position: 'center',
                valueAnimation: true
              }
            },

          ],
          legend: {x:'right', padding:[6,0,0,0],  data: ['销售额'] },
          animationDuration: 0,
          animationDurationUpdate: 3000,
          animationEasing: 'linear',
          animationEasingUpdate: 'linear'
        },
        groupID:0,
        span:8,
      }
    },
    methods: {
      getReport(){
        getReport(this.params).then(res=>{
          if(res.code===200){
            console.log(res)
            this.order_statistic.xAxis[0].data = res.data.xAxis
            this.order_statistic.series[0].data = res.data.yAxis[2]
            this.order_statistic.series[1].data = res.data.yAxis[3]
            this.order_statistic.series[2].data = res.data.yAxis[0]
            this.order_statistic_all_data = res.data.yAxis[1]
            this.mapOrderStatics()
          }
        })
      },
      getReportMonth(){
        getReportMonth(this.month_params).then(res=>{
          if(res.code===200){
            console.log(res)
            this.child_statistic.xAxis[0].data = res.data.xAxis
            this.child_statistic.series[0].data = res.data.yAxis[2]
            this.child_statistic.series[1].data = res.data.yAxis[3]
            this.child_statistic.series[2].data = res.data.yAxis[0]
            this.child_statistic_all_data = res.data.yAxis[1]
            this.mapChildStatics()
          }
        })
      },
      
      getReportGroup(){
        getReportGroup(this.params).then(res=>{
          if(res.code===200){
            console.log(res)
            this.goods_statistic.yAxis.data = res.data.yAxis
            this.goods_statistic.series[0].data = res.data.serial[1]
            this.mapGoodsStatics()
          }
        })
      },
      
      getReportStore(){
        getReportStore(this.params).then(res=>{
          if(res.code===200){
            console.log(res)
            this.gender_statistic.yAxis.data = res.data.yAxis
            this.gender_statistic.series[0].data = res.data.serial[1]
            this.mapGenderStatics()
          }
        })
      },
      
      
      // 获取订单列表
      mapEchartsInit () {
        this.mapChildStatics();
        this.mapGenderStatics();
        this.mapGoodsStatics();
        this.mapOrderStatics()
      },

      mapOrderStatics(){
        if(document.getElementById('mapOption').innerHTML){
          _this.$echarts.init(document.getElementById('mapOption')).clear()
        }
        _this.order_statistic && _this.$echarts.init(document.getElementById('mapOption')).setOption(_this.order_statistic,true);
      },

      mapChildStatics(){
        if(document.getElementById('childOption').innerHTML){
          _this.$echarts.init(document.getElementById('childOption')).clear()
        }
        _this.child_statistic && _this.$echarts.init(document.getElementById('childOption')).setOption(_this.child_statistic,true);
      },

      mapGoodsStatics(){
        _this.$echarts.init(document.getElementById('goodsOption')).setOption(_this.goods_statistic);
      },

      mapGenderStatics(){
        _this.$echarts.init(document.getElementById('genderOption')).setOption(_this.gender_statistic);
      },
      


    },
    mounted() {
      _this = this
      this.groupID = this.$store.state.user.userData.group_id
      if(this.groupID>1){
        this.span = 24/4
      }
      getListSelect().then(res=>{
        if(res.code === 200){
          this.storeList = res.data
        }
      })
      getGroups().then(res=>{
        if(res.code === 200){
          this.groupList = res.data
        }
      })
      index().then(res=>{
        if(res.code === 200){
          this.total_data = res.data
          console.log(res.data)
        }
      })
      this.getReport()
      this.getReportMonth()
      this.getReportStore()
      this.getReportGroup()
      
    },
  };
</script>

<style scoped lang="scss">
  @import "@/static/default";

  .temple {
    width: 100%;
    background-color: white;
    padding: 10px 50px;
    // padding-left: 50px;
  }

  .grid-content{
    
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    .title{
      width: 100%;
      font-size: 14px;
      color:gray;
    }
    .number{
      
      margin-top: 10px;
      width: 100%;
      font-size: 24px;
      font-weight: bold;
    }

    .week{
      padding: 5px 0;
      span{
        font-size: 14px;

      }
    }
    .daily{
      padding: 5px 0;
      span{
        font-size: 14px;

      }
    }
    .day{
      span{
        font-size: 16px;
      }
    }
  }

</style>
<style>
  .el-row {
    margin-bottom: 20px;
    width: 100%;
  &
  :last-child {
    margin-bottom: 0;
  }

  }
  .el-col {
    border-radius: 4px;
  }
</style>
